
import Vue from 'vue'

export default Vue.extend({
  name: 'RPHPaymentItem',
  props: {
    type: {
      type: String,
      default: 'visa',
    },
  },

  components: {
    VisaIcon: () => import('~/assets/icons/cards/visa.svg?inline'),
    MasterCardIcon: () => import('~/assets/icons/cards/master-card.svg?inline'),
  },
  data() {
    return {
      payments: {
        visa: {
          image: require('icons/visa-sm.png'),
          title: 'visa',
        },
        mastercard: {
          image: require('icons/mastercard-logo.png'),
          title: 'mastercard',
        },
      },
    }
  },
  computed: {},
  methods: {},
})
