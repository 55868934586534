
import SearchSquareIcon from 'icons/search/square-search-icon.svg?inline'
import breakpoints from '@/mixins/breakpoints'

/**
 * @vue-prop {String} value - input value
 * @vue-prop {Array} productsArr - finded items
 * @vue-prop {Boolean} loading - loading status
 */
export default {
  name: 'RPHInputSearch',
  components: { SearchSquareIcon },
  mixins: [breakpoints],
  props: {
    type: {
      type: String,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },

    isPharmacy: {
      type: Boolean,
      default: false,
    },

    noIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      focus: false,
      id: null,
    }
  },
  computed: {
    changePlaceholder() {
      return this.isPharmacy
        ? 'Поиск лекарств в данной аптеке'
        : this.screenWidth < 1260
          ? 'Поиск лекарств'
          : 'Введите название или активное вещество'
    },
  },
  mounted() {
    this.id = this._uid
  },
  methods: {
    /**
     * Method emits input value
     * @param {Event} e input event
     */
    onInput(e) {
      const value = e.target.value
      if (value.length >= 3) {
        const searchResult = document.getElementById(`rph-search-result-${this.type}`)
        searchResult.classList.remove('hidden')
      }
      this.$emit('input', value)
    },
    findProduct() {
      if (this.value) {
        this.$refs.search.blur()
        this.$emit('find-product')
      }
    },
    onClick() {
      this.$emit('open-results')
    },
  },
}
